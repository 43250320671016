<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
</script>

<template>
  <div class="container-auth">
    <AuthHeader/>
    <div class="auth-layout__breadcrumbs breadcrumbs">
      <img 
        src="/svg/auth/chevron-left.svg" 
        alt=""
      >
      <NuxtLink 
        to="/"
        class="breadcrumbs__link"
      >Назад</NuxtLink>
    </div>
    <div class="auth-layout__main">
      <AuthDecor class="auth-layout__decor" />
      <slot />
    </div>
  </div>
</template>

<style lang='scss'>
.auth-layout {
  &__main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (width < 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__breadcrumbs {
    height: 0;

      @media (width < 900px) {
         margin-block-start: 8px;
        height: auto;
      }
    }

  &__decor {
      @media (width < 900px) {
        display: none;
      }
    }
}

.breadcrumbs {
  display: flex;

  &__link {
    display: none;
    text-decoration: none;
    margin-inline-start: 8px;
    color: var(--c-text-primary);

    @media (width < 900px) {
      display: block;
    }
  }
}
</style>