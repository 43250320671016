<script setup>
</script>

<template>
  <div class="decor">
  </div>
</template>

<style lang='scss' scoped>
.decor {
  margin-bottom: 40px;
  background: url('/img/auth/auth.webp') no-repeat;
  background-size: contain;
}
</style>