<script setup lang="ts">
</script>

<template>
  <header 
    ref="authHeader" 
    class="auth-header"
  >
    <CommonLogo class="auth-header__logo"/>
    <HeaderLangSwitch class="auth-header__switch" />
  </header>
</template>

<style lang='scss' scoped>
.auth-header {
  margin-block: 40px;
  display: flex;
  justify-content: space-between;

  @media (width < 1024px) {
    margin-block: 12px;
  }

  &__logo,
  &__switch {
    @media (width < 1024px) {
      display: none;
    }
  }
}
</style>
